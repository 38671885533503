import { Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useContext, useEffect, useState } from 'react';
import { getDyadMeetingZoomInfo, getUsersMeetingInfo } from '../../../api/auth';
import { AuthContext } from '../../../context/authcontext';
import { DateTime, Duration } from 'luxon';



interface DataType {
    key: React.Key;
    patient: string;
    patient_email: string,
    care_partner_email: string,
    care_partner: string;
    meetingId: number;
    joinInPerson: boolean;
}

const buttonLinkStyles = {
    background:'transparent',
    border:'none',
    fontSize:'inherit',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'underline',
    color: 'blue',
    cursor: 'pointer',
}

const UsersZoomRecording = () => {
    const {user} = useContext(AuthContext);

    const [loading, setLoading] =  useState(false);
    const [allUsersRecInfo, setAllUsersRecInfo] = useState<null | any[]>(null);
    const [selectedDyadNumber, setSelectedDyadNumber] = useState(null);
    const [selectedDyadRecordingInfo, setSelectedDyadRecordingInfo] = useState<null |  any[]>(null);
    const selectedDyad = allUsersRecInfo?.find(record => record.dyad_number === selectedDyadNumber)

    const columns: ColumnsType<DataType> = [
        {
            title: 'Patient Name',
            dataIndex: 'patient',
            sorter: (a, b) => a.patient.localeCompare(b.patient),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Patient Email',
            dataIndex: 'patient_email',
            sorter: (a, b) => a.patient_email.localeCompare(b.patient_email),
        },
        {
            title: 'Care Partner Name',
            dataIndex: 'care_partner',
            sorter: (a, b) => a.care_partner.localeCompare(b.care_partner),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Care Partner Email',
            dataIndex: 'care_partner_email',
            sorter: (a, b) => a.care_partner_email.localeCompare(b.care_partner_email),
        },
        {
            title: 'Zoom Meetings',
            dataIndex: 'meetingId',
            render: (meetingIds: string[], row: any) => (
                <button
                    style={buttonLinkStyles}
                    onClick={() => setSelectedDyadNumber(row.dyad_number)}
                >{meetingIds.length} meetings</button>
            )
        },
        {
            title: 'Join In-Person',
            dataIndex: 'joinInPerson',
            filters: [
                {
                    text: 'Yes',
                    value: 'Yes',
                },
                {
                    text: 'No',
                    value: 'No',
                },
            ],
            // @ts-ignore
            onFilter: (value: string | number | boolean, record: DataType) => record.joinInPerson === value,
        },
    ];

    const rowClassName = (record: DataType, index: number) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };

    // Table data load on initial render
    useEffect(()=> {
        setLoading(true)
        getUsersMeetingInfo(user?.token).then((res: any)=> {
            setLoading(false);
            if(res.allUsersRecInfo){
                setAllUsersRecInfo(res.allUsersRecInfo)
            }
        }).catch(err=> {
            setLoading(false);
        })
    }, [])

    // Meeting recording load
    useEffect(() => {
        if (!selectedDyadNumber) {
            return setSelectedDyadRecordingInfo(null)
        }

        getDyadMeetingZoomInfo(user?.token, selectedDyadNumber).then((res: any) => {
            if(res.meetings) {
                setSelectedDyadRecordingInfo(res.meetings)
            } else {
                setSelectedDyadRecordingInfo([])
            }
        })
    }, [selectedDyadNumber])

    if (loading) {
        return <p>Loading recording list . . . </p>
    }

    if (!loading && !allUsersRecInfo) {
        return <p>Error loading recording list</p>
    }

    if (!selectedDyadNumber && allUsersRecInfo) {
        return(
            <>
                <Table tableLayout='fixed' pagination={{pageSize: 5}} style={{height: "100%", fontSize: "0.85vw"}} rowClassName={rowClassName} loading={loading} columns={columns} dataSource={allUsersRecInfo} />
            </>
        )
    }

    if (selectedDyadNumber && !selectedDyadRecordingInfo) {
        return <p>Fetching recordings for dyad {selectedDyadNumber} . . .</p>
    }

    if (selectedDyadNumber && selectedDyadRecordingInfo) {
        const rowData = selectedDyadRecordingInfo.map(meeting => {
            const selectedRecording = meeting.recording_files.find((recording:any) => recording.recording_type === 'shared_screen_with_speaker_view')
            if (!selectedRecording) return null

            const start = DateTime.fromISO(selectedRecording.recording_start)
            const end = DateTime.fromISO(selectedRecording.recording_end)
            let duration = end.diff(start).shiftTo("hours", "minutes", "seconds").normalize()

            if (!duration.get('hours') && !duration.get('minutes'))
                duration = duration.shiftTo('seconds')
            else if (!duration.get('hours'))
                duration = duration.shiftTo('minutes', 'seconds')
            else
                duration = duration.shiftTo('hours', 'minutes')

            return {
                time_start: start,
                duration: duration,
                play_url: selectedRecording.play_url,
                download_url: selectedRecording.download_url,
                passcode: encodeURIComponent(meeting.recording_play_passcode),
                file_size: getReadableFileSizeString(selectedRecording.file_size),
            }
        })

        const columns: ColumnsType<any> = [{
            title: 'Recording time',
            dataIndex: 'time_start',
            sorter: (a, b) => a.time_start.diff(b.time_start).as('seconds'),
            sortDirections: ['ascend', 'descend'],
            render: (time: DateTime) => time.toLocaleString(DateTime.DATETIME_MED)
        },{
            title: 'Duration',
            dataIndex: 'duration',
            render: (duration: Duration) => duration.toHuman()
        },{
            title: 'Actions',
            dataIndex: 'file_size',
            render: (size_bytes, row) => (<>
                <a href={row.play_url + `?pwd=${row.passcode}`} target="_blank">View / Download ({size_bytes})</a>
            </>)
        }]

        return (
            <div>
                <p><button onClick={() => setSelectedDyadNumber(null)} style={buttonLinkStyles}>⟵ Back to all dyads</button></p>
                <p><strong>Patient:</strong> {selectedDyad.patient}</p>
                <p><strong>Care Partner:</strong> {selectedDyad.care_partner}</p>

                <hr/>
                {/* @ts-ignore */}
                <Table tableLayout="fixed" style={{height: "100%", fontSize: "0.85vw"}} rowClassName={rowClassName} loading={false} columns={columns} dataSource={rowData} />
            </div>
        )
    }

    return <p>An unknown error occured</p>
}

function getReadableFileSizeString(fileSizeInBytes : number) {
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export default UsersZoomRecording;
